body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.selfPaced-container {
  padding: 20px;
  margin-bottom: 20px;
}

.selfPaced-title-section {
  margin-top: 80px;
}

.selfPaced-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selfPaced-title-content {
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.selfPaced-title-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.selfPaced-title-content h3 {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.selfPaced-description {
  font-size: 16px;
  line-height: 1.5;
}

.selfPaced-how-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.selfPaced-image-container {
  margin: 15px 0 20px 0;
  width: 85%;
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.selfPaced-image {
  width: 90%;
  height: auto;
  border-radius: 5px;
}

.selfLearning-contactUs-box {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.selfLearning-contactUs-btn {
  display: inline-block;
  background-color: #039eff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  width: 270px;
  text-align: center;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease-in-out;
}

.selfLearning-contactUs-btn:hover {
  background-color: #3182ce;
}

.selfLearning-contactUs-box p {
  color: #555;
}

@media screen and (min-width: 769px) {
  .selfPaced-container {
    margin: 20px auto 0 auto;
    max-width: 1200px;
    padding: 40px;
  }

  .selfPaced-title-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }

  .selfPaced-title-content {
    width: 70%;
    min-width: 400px;
    font-size: 20px;
    margin-right: 20px;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 50px;
  }

  .selfPaced-title-content h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    margin-left: 0;
    text-align: left;
  }

  .selfPaced-title-content h3 {
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
  }

  .selfPaced-description {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 1.5;
  }

  .selfPaced-image-container {
    max-width: 500px;
    min-width: 300px;
    height: auto;
    margin-top: 140px;
    margin-left: 40px;
  }

  .selfLearning-contactUs-btn {
    font-size: 18px;
    padding: 15px;
    width: 290px;
    margin-bottom: 15px;
  }

  .selfPaced-how-container {
    align-items: flex-start;
  }
}

@media screen and (min-width: 1025px) {
  .selfPaced-container {
    margin: 40px auto 0 auto;
    max-width: 1500px;
    padding: 60px;
  }

  .selfPaced-title-content h1 {
    font-size: 2.75rem;
  }
}
