.faq-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  position: absolute;
}

.faq-title-section {
  color: white;
  position: relative;
  height: 220px;
  text-align: center;
  max-width: 1200px;
  margin-bottom: 40px;
  background-image: url('/public/Faq.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 40px;
  overflow: hidden;
}

.faq-title-section h1 {
  margin-top: 80px;
  font-size: 1.75rem;
  padding-top: px;
}

.faq-image {
  display: none;
  margin-top: 20px;
}

.accordion-section {
  width: 100%;
  padding: 10px;
}

.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 1px 2px #00000033;
  min-height: 50px;
  margin-bottom: 20px;
  overflow: auto;
}

.toggled {
  border-color: #039eff;
}

.accordion .toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 10px 15px;
  fill: black;
  color: black;
  transition: color 0.15s ease, fill 0.15s ease;
  font-weight: 600;
  border: none;
  background-color: transparent;
  cursor: pointer;
  min-height: 45px;
}

.accordion .toggle p {
  text-align: left;
}

.accordion .toggle:hover {
  color: #6d6d6d;
  fill: #6d6d6d;
}

.accordion .toggle .direction-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #039eff;
  padding-left: 5px;
}

.accordion .content-parent {
  max-height: 0;
  overflow-y: auto;
  transition: max-height 0.15s ease-out;
  color: black;
}

.accordion.toggled .content-parent {
  max-height: 800px;
  overflow-y: auto;
  transition: max-height 0.25s ease-in;
}

.accordion .content {
  padding: 10px 30px;
  line-height: 1.6;
  font-size: 14px;
}

/* Media query for tablets and laptops */
@media screen and (min-width: 769px) {
  .faq-container {
    margin-top: 65px;
    width: 100%;
  }

  .faq-title-section {
    height: 320px;
    width: 1000px;
    background-size: 100% 90%;
  }

  .faq-title-section h1 {
    padding-top: 50px;
    font-size: 2.5rem;
    z-index: 2;
  }

  .accordion .toggle {
    font-size: 1.15rem;
    padding: 15px 20px;
  }

  .accordion .content {
    padding: 15px 40px;
    font-size: 1rem;
  }
}

/* Media query for desktop monitors */
@media screen and (min-width: 1025px) {
  .faq-container {
    margin-top: 130px;
  }

  .faq-title-section {
    width: 1000px;
    height: 320px;
    background-size: 100% 100%;
  }

  .accordion-section {
    width: 60%;
  }

  .faq-title-section h1 {
    font-size: 2.75rem;
  }

  .accordion .toggle {
    font-size: 1.2rem;
    padding: 20px 25px;
  }

  .accordion .content {
    padding: 20px 50px;
    font-size: 1.1rem;
  }
}
