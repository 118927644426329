.course-categories-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
}

.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.category-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 170px;
  padding: 20px 40px;
  color: #0077cc;
  background-color: white;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  z-index: 1; /* Ensure it's above the pseudo-elements */
}

.category-link::after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  left: 0;
  top: 0;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

/* Glowing effect */
.category-link::before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -2; /* Behind everything */
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 0; /* Hidden by default */
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Hover effect */
.category-link:hover::before {
  opacity: 1; /* Show glowing effect on hover */
}

.category-link:active:after {
  background: transparent;
}

.category-link:active {
  color: black;
  font-weight: bold;
}

@media (min-width: 769px) {
  .categories {
    flex-direction: row;
    gap: 50px;
  }

  .category-link {
    font-size: 22px;
    width: 390px;
    height: 210px;
  }
}

@media screen and (min-width: 1025px) {
  .categories {
    gap: 80px;
  }

  .category-link {
    font-size: 24px;
    width: 420px;
    height: 220px;
  }
}
