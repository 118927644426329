.ClassIntro-container {
  min-height: 100vh;
  padding: 20px;
}

.ClassIntro-title-section {
  color: black;
  margin-top: 80px;
}

.ClassIntro-title-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-items: flex-start;
}

.ClassIntro-title-content h1 {
  font-size: 1.8rem;
  position: relative;
  margin-bottom: 20px;
}

.ClassIntro-description {
  font-size: 14px;
  line-height: 1.6;
  font-weight: normal;
  margin-bottom: 20px;
}

.ClassIntro-image-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.ClassIntro-img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 0 10px 5px rgba(0, 119, 204, 0.5);
}

@media screen and (min-width: 769px) {
  .ClassIntro-container {
    margin: 140px auto 2rem;
    max-width: 1200px;
    padding: 30px;
  }

  .ClassIntro-title-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .ClassIntro-title-content {
    width: 70%;
    min-width: 400px;
    margin-right: 25px;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 50px;
  }

  .ClassIntro-title-content h1 {
    font-size: 2.75rem;
    margin-bottom: 50px;
    text-align: left;
    margin-left: 0;
  }

  .ClassIntro-description {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.8;
  }

  .ClassIntro-image-container {
    max-width: 500px;
    height: auto;
    margin-left: 40px;
  }

  .ClassIntro-img {
    width: 400px;
    height: 400px;
  }
}
