.course-card {
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgba(160, 160, 177, 0.612);
    background-color: black;
    width: 30%;
    height: 400px;
    min-width: 300px;
    padding: 1em;
    margin: 1em;
    text-align: center;
    display: inline-block;
}

.course-card:hover {
    transform: scale(1.05);
}

.course-card .course-card-image {
    height: 100px;
}

.course-card .course-card-title {
    font-family: Arial, sans-serif;
    margin: 0;
    color: #a0c9f8;
}

.course-card .course-card-desc {
    font-family: Arial, sans-serif;
    font-size: 1.3em;
    margin: 1;
    color: #ffffff;
    padding: 1em;
}

.course-card-desc {
    margin-bottom: 1em;
}