.blog-post-container {
  font-family: Arial, sans-serif;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
}

.blog-post-title {
  margin-bottom: 20px;
  font-size: 1.75rem;
}

.blog-post-publisher {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #039eff;
}

.blog-post-image {
  max-height: 220px;
  width: 100%;
  margin-bottom: 30px;
  object-fit: cover;
}

.blog-post-content {
  line-height: 1.6;
}

.blog-post-heading {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 20px;
}

.blog-post-paragraph {
  text-align: justify;
  font-size: 16px;
}

.blog-post-list {
  font-size: 16px;
  padding-left: 15px;
  margin: 0;
  list-style-type: disc;
}

@media (min-width: 769px) {
  .blog-post-container {
    flex-direction: column;
    margin-top: 120px;
    padding: 40px;
    margin-bottom: 80px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-post-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
  }

  .blog-post-image {
    max-height: 320px;
  }

  .blog-post-content {
    line-height: 1.7;
  }

  .blog-post-heading {
    margin-top: 40px;
    font-size: 24px;
  }

  .blog-post-paragraph {
    font-size: 18px;
  }

  .blog-post-list {
    font-size: 18px;
  }
}
