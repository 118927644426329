footer {
  background: var(--black-color);
  padding: 5px 20px;
  text-align: center;
}

footer a {
  text-decoration: none;
  color: var(--black-color);
  margin: 0 10px;
}

.icons {
  margin-bottom: 2px;
  margin-top: 3px;
}

.copy-right {
  color: var(--black-color);
  font-size: small;
  color: white;
}

.footer-icons {
  font-size: 20px;
  color: white;
}

@media screen and (min-width: 769px) {
  footer {
    padding: 20px;
  }

  .footer-icons {
    font-size: 24px;
  }

  .icons {
    margin-bottom: 10px;
  }

  .copy-right {
    font-size: 16px;
  }
}
