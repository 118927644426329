body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tutoring-container {
  padding: 10px;
  margin-bottom: 20px;
}

.tutoring-title-section {
  margin-top: 80px;
}

.tutoring-title-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tutoring-title-content {
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.tutoring-title-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.tutoring-title-content h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.white-text {
  color: white;
}

.tutoring-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.tutoring-description {
  margin-bottom: 20px;
  line-height: 1.5;
  color: white;
}

.book-session-btn {
  display: inline-block;
  background-color: #039eff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  width: 270px;
  text-align: center;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.book-session-btn:hover {
  background-color: #3182ce;
}

.tutor-image-container {
  margin-top: 15px;
  width: 85%;
  height: auto;
}

.tutor-image {
  width: 90%;
  height: auto;
  border-radius: 5px;
}

.tutor-section-title {
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.why-container {
  padding: 10px;
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.feature {
  background-color: whitesmoke;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.feature-description {
  font-size: 16px;
  line-height: 1.5;
}

.how-container {
  padding: 10px;
  margin-top: 20px;
}

.step-list {
  list-style-type: decimal;
  list-style-position: inside;
  padding-left: 1rem;
  line-height: 1.6;
  color: white;
}

.step-list li {
  margin-bottom: 10px;
  font-size: 16px;
}

.pricing-box {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.pricing-box-top {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.price-title {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.price {
  font-size: 16px;
}

.financial-assistance {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

@media screen and (min-width: 769px) {
  .tutoring-container {
    margin: 20px auto 0 auto;
    max-width: 1200px;
    padding: 40px;
  }

  .tutoring-title-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }

  .tutoring-title-content {
    width: 70%;
    min-width: 400px;
    font-size: 20px;
    margin-right: 20px;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 50px;
  }

  .tutoring-title-content h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    margin-left: 0;
    text-align: left;
  }

  .tutoring-title-content h3 {
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
  }

  .tutoring-description {
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 1.5;
  }

  .tutor-image-container {
    max-width: 500px;
    min-width: 300px;
    height: auto;
    margin-top: 140px;
    margin-left: 40px;
  }

  .book-session-btn {
    font-size: 18px;
    padding: 15px;
    width: 290px;
  }

  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: left;
  }

  .why-section {
    margin-top: 40px;
  }

  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .step-list li {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .how-section {
    margin-top: 40px;
  }

  .how-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .how-content-wrapper {
    width: 65%;
    margin-right: 20px;
  }

  .how-content {
    font-size: 20px;
    margin-top: 10px;
  }

  .book-session-btn {
    padding: 15px;
  }

  .pricing-box {
    margin-top: 50px;
    padding: 20px;
  }

  .price-title {
    font-size: 18px;
  }

  .price {
    font-size: 18px;
  }

  .financial-assistance {
    font-size: 16px;
  }

  .selfLearning-contactUs-box {
    padding: 25px;
    width: 420px;
  }
}

@media screen and (min-width: 1025px) {
  .tutoring-container {
    margin: 40px auto 0 auto;
    max-width: 1500px;
    padding: 60px;
  }

  .why-section {
    margin-top: 60px;
  }

  .features-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
