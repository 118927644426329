.Course-container {
  background-color: black;
  color: white;
  min-height: 100vh;
  max-width: 800px;
  margin-top: 55px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spline-background-wrapper {
  position: absolute;
  height: 160px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  z-index: 1;
  pointer-events: none;
}

.spline-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Course-name-overlay {
  color: black;
  position: absolute;
  max-width: 65%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 2;
  text-align: left;
  padding-left: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

.Course-overview {
  margin-top: 190px;
  margin-left: 5px;
}

.Course-top-main-content {
  display: flex;
  gap: 5px;
}

.Course-sidebar {
  padding: 20px 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  margin: 60px 10px 20px 0;
  background-color: rgb(28, 15, 111);
  min-width: 120px;
  text-align: left;
  border-radius: 5px;
  z-index: 2;
}

.Course-bottom-section {
  padding: 10px;
}

.Course-topics-section {
  background-image: url('/public/Topic-balls.png');
  background-size: 720px;
  background-repeat: no-repeat;
  background-position: -125px 0px;
  padding: 20px;
  margin: 0 -15px;
}

.Course-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.Course-overview {
  padding: 10px;
}

.Course-details {
  margin-bottom: 30px;
  line-height: 1.7;
  font-size: 15px;
}

.Course-apply-btn {
  white-space: nowrap;
  background-color: #0077cc;
  color: white;
  font-size: medium;
  padding: 8px 16px;
  border: none;
  border-radius: 25px;
  text-align: center;
  display: inline-block;
  margin-bottom: 16px;
  cursor: pointer;
  transition: background-color 200ms;
}

.Course-apply-btn:hover {
  background-color: #014c98;
}

.Course-header-side {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 40px;
}

.Course-schedule {
  font-size: 14px;
  margin-bottom: 20px;
}

.Course-startAndEnd {
  font-weight: bold;
  margin-bottom: 10px;
}

.Course-side-details {
  font-size: 14px;
}

.Course-link {
  color: #0077cc;
  font-size: 0.8rem;
}

.tiny-font {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.tiny-font-blue {
  font-size: 15px;
  margin-top: 15px;
}

.Course-topic-title {
  font-size: 15px;
  font-weight: bold;
  list-style-type: circle;
  margin-bottom: 10px;
}

.Course-topics-list {
  list-style-type: disc;
  margin-bottom: 20px;
  font-size: 15px;
}

.Course-topics-list li {
  margin-bottom: 10px;
  margin-left: 30px;
}

@media screen and (min-width: 769px) {
  .Course-container {
    margin-top: 85px;
    margin-bottom: 60px;
    max-width: 1000px;
  }

  .spline-background-wrapper {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    pointer-events: none;
  }

  .Course-name-overlay {
    max-width: 75%;
    font-size: 2.5rem;
    font-weight: bold;
    margin-left: 40px;
  }

  .Course-sidebar {
    padding: 30px 20px;
    min-width: 330px;
    border-radius: 10px;
    margin: 170px 20px 20px;
  }

  .Course-overview {
    margin: 280px 20px 0 40px;
  }

  .Course-topics-section {
    background-image: url('/public/Topic-balls.png');
    background-size: 100%;
    background-position: 70px -20px;
  }

  .Course-bottom-section {
    padding: 40px;
    max-width: 100%;
  }

  .Course-top-main-content {
    gap: 0px;
  }

  .Course-topics-list {
    margin-bottom: 20px;
  }

  .Course-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .Course-details {
    margin-bottom: 50px;
    line-height: 1.8;
    font-size: 18px;
  }

  .Course-topic-title {
    font-size: 18px;
  }

  .Course-topics-list {
    font-size: 18px;
  }

  .Course-topics-list li {
    margin-left: 35px;
  }

  .Course-header-side {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .Course-schedule {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .Course-startAndEnd {
    margin-bottom: 15px;
    margin-top: 5px;
  }

  .Course-side-details {
    font-size: 16px;
  }

  .Course-link {
    font-size: 1rem;
  }

  .tiny-font {
    margin-top: 15px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1025px) {
  .Course-container {
    max-width: 1500px;
    margin-top: 85px;
    padding: 0;
  }

  .spline-background-wrapper {
    height: 280px;
    margin-bottom: 30px;
    max-width: 100vw;
  }

  .Course-name-overlay {
    font-size: 3rem;
    padding-left: 120px;
  }

  .Course-sidebar {
    padding: 40px 30px;
    min-width: 400px;
    margin: 200px 20px 20px;
  }

  .Course-header {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .Course-overview {
    margin: 320px 40px 120px 150px;
    max-width: 830px;
  }

  .Course-topics-section {
    background-image: url('/public/Topic-balls.png');
    background-size: 115%;
    background-position: -10px -45px;
  }

  .Course-bottom-section {
    margin-left: 120px;
  }
}
