.category-box-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 40px;
}

.category-box-container h1 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 22px;
}

.course-category-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #039eff;
}

.course-category {
  align-items: center;
}

.course-box {
  border: 1px solid #ccc;
  padding: 20px;
  min-height: 150px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
  transition: background-color 0.3s, box-shadow 0.3s;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.course-box:hover {
  background-color: #f0f0f0;
  border-color: #039eff;
}

.course-box:hover .chevron-icon {
  color: #039eff;
}

.chevron-icon {
  position: absolute;
  right: 5px;
  top: 35px;
  padding: 5px;
}

.course-name {
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 7px;
}

.course-delivery {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
  color: black;
}

.course-schedule {
  color: black;
  font-size: 14px;
  margin-bottom: 3px;
}

.course-startAndEnd {
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

@media screen and (min-width: 769px) {
  .category-box-container {
    overflow: hidden;
    margin-top: 90px;
    margin-bottom: 60px;
  }

  .course-category-title {
    margin-top: 30px;
    font-size: 24px;
  }

  .course-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 85%;
  }

  .category-box-container h1 {
    margin-bottom: 40px;
    margin-top: 40px;
    font-size: 2.5em;
  }

  .course-box {
    flex: 1 1 calc(50% - 20px);
    min-height: 200px;
    max-height: 270px;
    padding: 30px;
    margin: 10px 0;
    max-width: 50%;
  }

  .course-box:hover .chevron-icon {
    color: #039eff;
  }

  .course-name {
    color: black;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .course-delivery {
    color: black;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .course-startAndEnd {
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .course-schedule {
    color: black;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) {
  .category-box-container {
  }
}
