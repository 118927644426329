.containers {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.form {
  width: 100%;
  margin-top: 20px;
  max-width: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  display: grid;
}

.contact-form {
  background-color: var(--black-color);
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #0077cc);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

/* side blue triangle */
.contact-form:before {
  content: '';
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #0077cc;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 20px;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title-container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.input-container select {
  font-size: 0.85rem;
  padding: 10px;
  color: #fff;
}

.btn {
  padding: 0.6rem 1.5rem;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #039eff;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 0 0 5px;
}

.btn:hover {
  background-color: #039eff;
  color: #fff;
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

.captcha-and-button {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (min-width: 769px) {
  .containers {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .form {
    max-width: 500px;
  }

  form {
    padding: 2.7rem 2.3rem;
  }

  .input-container {
    padding: 2px;
  }

  .input {
    padding: 0.7rem 1.2rem;
    font-size: 1rem;
  }

  .input-container select {
    font-size: 0.9rem;
    padding: 10px;
    color: #fff;
  }

  .btn {
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .captcha-and-button {
    flex-direction: row; /* Reverts to row layout on larger screens */
    justify-content: space-between;
    align-items: center;
  }

  .contactUs-captcha {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1025px) {
  .containers {
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .form {
    max-width: 570px;
  }

  .input-container {
    padding: 3px;
  }

  .input-container select {
    font-size: 1rem;
    padding: 12px;
  }

  .btn {
    padding: 0.8rem 1.85rem;
    font-size: 1.15rem;
  }
}
