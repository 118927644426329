/* Base styles for mobile devices */
.main-page-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.main-img-container {
  height: 100vh;
  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  padding: 10px;
}

.header-section {
  justify-content: center;
  display: block;
  width: 100%;
  padding-top: 15%;
  text-align: center;
  border: 1px solid green;

}

.main-classroomImg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/Classroom-mono.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-classroomImg-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 120, 255, 0.5); /* Blue overlay color with transparency */
}

.main-text-section {
  margin-top: 30px;
  position: relative;
  text-align: center;
  color: white;
}

.main-text-section p {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  display: block;
  white-space: pre-wrap;
  line-height: 1.5;
}

.main-text-section h1 {
  font-size: 36px;
}

.link-btn-container {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: hsl(220, 24%, 12%);
  color: white;
  width: 80%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: black;
  transform: scale(1.08);
}

.tech-data-section {
  margin-top: 50px;
}

.main-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (min-width: 769px) {
  .main-page-container {
    margin-top: 20px;
  }

  .main-img-container {
    height: 600px;
    padding: 20px;
  }

  .main-text-section {
    margin-bottom: 70px;
  }

  .main-text-section p {
    font-size: 20px;
  }

  .main-text-section h1 {
    font-size: 3em;
  }

  .link-btn-container {
    padding: 20px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
  }

  .spline-robot-wrapper {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 780px;
    width: 100%;
    top: 0;
    left: 0;
  }

  .ATA-robot {
    transform: scale(0.8);
  }

  .button {
    display: inline-block;
    width: 300px;
    padding: 17px;
    margin-right: 20px;
    font-weight: bold;
    transition: all 0.3s;
  }

  .button:hover {
    transform: scale(1.05);
  }

  .header-title {
    font-size: 2rem;
  }

  .tech-data-section {
    margin-top: 60px;
  }

  .main-title {
    font-size: 32px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1025px) {
    .main-img-container {
      height: 750px;
    }

    .main-text-section {
      margin-bottom: 100px;
    }

    .spline-robot-wrapper {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      height: 825px;
      width: 100%;
      top: 0;
      left: 0;
    }

    .ATA-robot {
      transform: scale(1);
    }

    .main-title {
      font-size: 40px;
      margin-bottom: 40px;
    }
  }
}
