.blog-container {
  position: relative;
  height: 100vh;
  margin-top: 80px;
  padding: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
}

.blogPage-header {
  font-size: 1.8rem;
  margin: 0;
  color: black;
  text-align: center;
  margin-bottom: 5px;
}

.blogPage-subtitle {
  font-size: 16px;
  color: #686d76;
  margin: 0;
  text-align: center;
}

.blog-scrollable {
  overflow-y: auto;
  width: 100%;
  max-height: 90vh;
  overflow-x: hidden;
}

.blog-list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.blog-rectangle {
  display: flex;
  background: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease;
}

.blog-rectangle:hover {
  transform: scale(1.03);
}

.blog-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-right: 10px;
}

.blog-content {
  flex: 1;
}

.blog-title {
  font-size: 18px;
  margin: 0 0 5px;
}

.blog-author {
  font-size: 12px;
  font-weight: bold;
  color: #039eff;
  margin-bottom: 5px;
}

.blog-date {
  font-size: 12px;
  color: #686d76;
  margin-bottom: 10px;
}

.blog-excerpt {
  margin-top: 5px;
  font-size: 14px;
  color: black;
}

.blog-endPosts {
  margin-top: 20px;
}

@media (min-width: 769px) {
  .blog-container {
    margin-top: 120px;
    padding: 30px;
  }

  .blogPage-header {
    font-size: 2.5rem;
  }

  .blogPage-subtitle {
    font-size: 20px;
  }

  .blog-scrollable {
    margin-top: 30px;
    overflow-y: auto;
    max-height: 1000vh;
    max-width: 1400px;
    justify-content: center;
  }

  .blog-list {
    margin-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px;
    gap: 30px;
  }

  .blog-rectangle {
    width: calc(50% - 20px);
    padding: 12px;
    margin: 0 auto;
  }

  .blog-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 15px;
  }

  .blog-title {
    font-size: 20px;
    margin: 0 0 7px;
  }

  .blog-author {
    font-size: 14px;

    margin-bottom: 7px;
  }

  .blog-date {
    font-size: 14px;

    margin-bottom: 10px;
  }

  .blog-excerpt {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1025px) {
  .blog-container {
    padding: 50px;
  }

  .blogPage-header {
    font-size: 2.75rem;
  }

  .blog-scrollable {
    max-width: 1500px;
    overflow-y: auto;
    max-height: 60vh;
    max-width: 1400px;
    justify-content: center;
  }
}
