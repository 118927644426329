body {
    background-color: black;
}
.landing-page-banner-div {
    padding-top: 15%;
    display: block;
    width: 100%;
    text-align: center;
    background-image: url('../../../assets/Classroom-blue.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: rgb(19, 18, 73);
}

.langing-page-h1, .landing-page-desc {
    font-family: Arial, Helvetica, sans-serif;
}

.langing-page-h1 {
    font-size: 3em;
}

.landing-page-desc {
    font-size: 1.5em;
    margin-left: 2em;
    margin-right: 2em;
    padding: 0.5em;
    background-color: rgb(106, 171, 228);
    border-radius: 30px;
}

/* Keeps the card divs on the same line. Otherwise some go higher/lower */
@media screen and (min-width: 800px) {
    .course-cards-main-div {
        justify-content: center;
        display: flex;
    }
}

.header-btn-div {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
}
  
.landing-header-button {
    display: inline-block;
    padding: 1em;
    margin: 1em;
    background-color: hsl(220, 24%, 12%);
    color: white;
    width: 15%;
    min-width: 150px;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    transition: background-color 0.3s, transform 0.2s;
    
}
  
.landing-header-button:hover {
    background-color: black;
    transform: scale(1.08);
}