.tutorRequest-container {
  max-width: 100%;
  margin: 5px;
  margin-top: 80px;
  margin-bottom: 60px;
  padding: 5px;
}

.tutorRequest-container h1 {
  text-align: center;
  color: black;
  font-size: 1.8em;
}

.tutorReq-form-group {
  margin-bottom: 15px;
}

.tutorReq-form-group .tutorReq-input,
.tutorReq-form-group .tutorReq-textarea,
.tutorReq-form-group .tutorReq-select {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  color: black;
  box-sizing: border-box;
  cursor: pointer;
}

.tutorReq-form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.tutorReq-textarea {
  height: 100px;
}

.subject-checkbox-group {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.subject-checkbox-group .subject-checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.subject-checkbox-group .subject-checkbox-item input {
  margin-right: 10px;
}

.subject-checkbox-group label {
  margin-left: 5px;
  margin-right: 30px;
  margin-top: 10px;
}

.tutorReq-captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.tutorReq-button {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #039eff;
  color: #fff;
  font-size: 1.2em;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

.tutorReq-button:hover {
  background-color: #0579c2;
}

@media screen and (min-width: 769px) {
  .tutorRequest-container {
    max-width: 700px;
    margin: 30px auto;
    margin-top: 120px;
    padding: 30px;
  }

  .tutorRequest-container h1 {
    font-size: 2.2em;
  }

  .tutorReq-form-group {
    margin-bottom: 20px;
  }

  .tutorReq-form-group .tutorReq-input,
  .tutorReq-form-group .tutorReq-textarea,
  .tutorReq-form-group .tutorReq-select {
    padding: 15px;
    font-size: 16px;
  }
  .tutorReq-captcha {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) {
  tutorRequest-container {
    margin: 50px auto;
    margin-top: 120px;
    padding: 10px;
  }

  .tutorRequest-container h1 {
    font-size: 2.5em;
  }

  .tutorReq-form-group label {
    font-size: medium;
  }

  .tutorReq-button {
    padding: 12px;
    margin-top: 30px;
  }
}
