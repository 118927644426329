.container-apply-form {
  max-width: 100%;
  margin: 5px;
  margin-top: 80px;
  margin-bottom: 40px;
  padding: 5px;
}

.container-apply-form h1 {
  text-align: center;
  color: black;
  font-size: 1.8em;
}

.apply-section-title {
  font-size: 1.2em;
  margin-bottom: 20px;
  margin-top: 30px;
  color: #039eff;
  font-size: 1.5em;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: black;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  color: black;
  cursor: pointer;
}

.form-group.inline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.form-group.inline .form-group {
  flex: 1;
  margin-right: 10px;
}

.form-group.inline .form-group:last-child {
  margin-right: 0;
}

textarea.large-textarea {
  height: 100px;
}

textarea.small-textarea {
  height: 50px;
}

.form-group .note {
  font-size: 0.9em;
  color: #888;
}

input[type='checkbox'] {
  width: 15px;
  height: 15px;
  transform: scale(1.5);
  margin-right: 10px;
}

#application-form-white-label {
  font-size: 1.2em;
  color: white;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.checkbox-group label {
  margin-left: 5px;
  margin-right: 30px;
  margin-top: 10px;
  color: white;
}

.application-captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #039eff;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  margin-top: 30px;
  cursor: pointer;
  text-align: center;
}

.submit-btn:hover {
  background-color: #0579c2;
}

@media screen and (min-width: 769px) {
  .container-apply-form {
    max-width: 700px;
    margin: 30px auto;
    padding: 15px;
  }

  .container-apply-form h1 {
    font-size: 2.2em;
  }

  .apply-section-title {
    font-size: 1.7em;
  }
}

@media screen and (min-width: 1025px) {
  .container-apply-form {
    max-width: 800px;
    margin: 50px auto;
    margin-top: 120px;
    margin-bottom: 80px;
    padding: 10px;
  }

  .container-apply-form h1 {
    font-size: 2.5em;
  }

  .apply-section-title {
    font-size: 1.9em;
  }

  .form-group label {
    font-size: medium;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .apply-section-title {
    font-size: 1.2em;
    margin-bottom: 25px;
    margin-top: 50px;
    font-size: 1.9em;
    text-align: center;
  }

  .form-group.inline {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .form-group.inline .form-group {
    flex: 1;
    margin-right: 10px;
  }

  .form-group.inline .form-group:last-child {
    margin-right: 0;
  }

  .submit-btn {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: #039eff;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 30px;
    cursor: pointer;
    text-align: center;
  }
}
