.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  margin: 20px;
  min-height: 100vh;
  box-sizing: border-box;
}

.not-found-title-box {
  text-align: left;
  width: 100%;
}

.not-found-404 {
  font-size: 80px;
  font-weight: 700;
  color: grey;
}

.not-found-text {
  max-width: 60%;
  font-size: 22px;
  color: hsl(220, 20%, 18%);
  font-weight: 600;
}

.not-found-small-text {
  font-size: 16px;
  font-weight: 400;
  color: hsl(220, 20%, 18%);
  margin-top: 5px;
  white-space: nowrap;
}

.not-found-small-text a {
  color: #0077cc;
  text-decoration: none;
  cursor: pointer;
}

.not-found-bot-container {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 280px;
  height: 280px;
  background: radial-gradient(
    circle,
    rgb(255, 255, 255),
    rgba(207, 225, 232, 0.757)
  );
  border-radius: 50%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.not-found-bot-image {
  max-width: 150px;
  height: auto;
  margin: 70px 0 0 90px;
}

@media screen and (min-width: 769px) {
  .not-found-container {
    padding: 60px;
    margin-top: 40px;
  }

  .not-found-title-box {
    margin: 0 auto;
  }

  .not-found-404 {
    font-size: 120px;
  }

  .not-found-text {
    font-size: 24px;
    font-weight: 600;
  }

  .not-found-small-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }

  .not-found-bot-container {
    width: 370px;
    height: 370px;
    background: radial-gradient(
      circle,
      rgb(255, 255, 255),
      rgba(207, 225, 232, 0.757)
    );
    border-radius: 50%;
    margin-top: 20px;
  }

  .not-found-bot-image {
    max-width: 170px;
    height: auto;
    margin: 130px 0 0 140px;
  }
}

@media screen and (min-width: 1025px) {
  .not-found-container {
    padding: 120px;
  }

  .not-found-404 {
    font-size: 150px;
  }

  .not-found-text {
    font-size: 26px;
  }

  .not-found-bot-container {
    width: 400px;
    height: 400px;
    background: radial-gradient(
      circle,
      rgb(255, 255, 255),
      rgba(207, 225, 232, 0.757)
    );
    border-radius: 50%;
    margin-top: 40px;
  }

  .not-found-bot-image {
    max-width: 200px;
    height: auto;
    margin: 130px 0 0 160px;
  }
}

@media screen and (max-height: 500px) and (min-width: 666px) {
  .not-found-container {
    margin-top: 40px;
    flex-direction: row; /* horizontal display */
    align-items: center;
    justify-content: space-between;
  }

  .not-found-bot-container {
    width: 200px;
    height: 200px;
    background: radial-gradient(
      circle,
      rgb(255, 255, 255),
      rgba(207, 225, 232, 0.757)
    );
    border-radius: 50%;
  }

  .not-found-bot-image {
    max-width: 130px;
    height: auto;
    margin: 0;

    margin: 40px 0 0 50px;
  }

  .not-found-title-box {
    margin-left: 20px;
    width: auto;
  }

  .not-found-404 {
    font-size: 90px;

    .not-found-text {
      font-size: 18px;
    }

    .not-found-small-text {
      font-size: 14px;
    }
  }
}
