.financial-aid-title-1 {
  color: white;
}


.financialAid-container {
  padding: 20px;
}

.container-finance {
  min-height: 100vh;
  margin: 80px auto 2rem;
  max-width: 600px;
}

.top-page-content {
  display: flex;
  justify-content: center;
}

.top-page-content h1 {
  font-size: 1.25rem;
  font-weight: bold;
}

.title-content {
  padding-right: 20px;
}

.title-blue {
  color: #039eff;
  margin-top: 10px;
}

.classroom-img {
  width: 180px;
  height: auto;
  border-radius: 5px;
}

.finance-content {
  color: white;
  margin: 0 auto 2rem;
  text-align: left;
  margin-top: 30px;
  font-size: 16px;
  line-height: 1.6;
}

/* Media query for tablets and laptops */
@media screen and (min-width: 769px) {
  .container-finance {
    margin: 120px auto 2rem;
    max-width: 800px;
    padding: 20px;
  }

  .top-page-content {
    display: flex;
    padding: 20px, 40px;
    justify-content: center;
  }

  .top-page-content h1 {
    font-size: 2rem;
  }

  .title-content {
    padding-right: 20px;
    margin-top: 70px;
  }

  .classroom-img {
    width: 90%;
    height: auto;
    border-radius: 5px;
  }

  .finance-content {
    font-size: 20px;
    max-width: 700px;
  }
}

/* Media query for desktop monitors */
@media screen and (min-width: 1025px) {
  .container-finance {
    max-width: 1400px;
    padding: 20px;
  }

  .top-page-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .title-content {
    padding-right: 70px;
    margin-top: 140px;
  }

  .classroom-img {
    border-radius: 10px;
    max-width: 500px;
    height: auto;
  }

  .finance-content {
    margin: 0 auto 2rem;
    text-align: left;
    margin-top: 40px;
    font-size: 20px;
    max-width: 900px;
    line-height: 1.7;
  }
}
