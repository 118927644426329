.container-career-paths {
  margin-top: 90px;
  padding: 10px;
  text-align: center;
  margin-bottom: 40px;
}

.container-career-paths h1 {
  font-size: 22px;
  margin-bottom: 20px;
}

.paths-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.path {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

.title-in-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.title-in-circle a {
  position: absolute;
  top: 65%;
  font-size: 14px;
  color: blueviolet;
  cursor: pointer;
}

.front-end {
  background-color: #21a6f837;
  border: 2px solid hsla(203, 94%, 55%, 0.498);
}

.back-end {
  background-color: #039eff83;
  border: 2px solid #058ce0ac;
}

.full-stack {
  background-color: #058de8b7;
  border: 2px solid #046bb0d5;
}

.cybersecurity {
  background-color: rgba(105, 235, 146, 0.438);
  border: 2px solid rgb(70, 216, 131);
}

.paths-duration {
  font-size: 12px;
  margin-top: 10px;
}

.course-name-light,
.course-name-medium,
.course-name-green {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 50px;
  width: 100%;
  height: auto;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: center;
  white-space: normal;
  cursor: pointer;
  transition: all 0.3s;
}

.course-name-light {
  background-color: #21a6f837;
  border: 2px solid #21a6f87f;
}

.course-name-medium {
  background-color: #039eff83;
  border: 2px solid #058ce0ac;
}

.course-name-green {
  background-color: rgba(105, 235, 146, 0.438);
  border: 2px solid rgb(70, 216, 131);
}

.course-name-light:hover,
.course-name-medium:hover,
.course-name-green:hover {
  box-shadow: 3px 3px 7px 1px black;
}

.course-name-light-fs,
.course-name-medium-fs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
  width: 170px;
  height: auto;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: center;
  white-space: normal;
  word-break: break-word;
  cursor: pointer;
  transition: all 0.3s;
  overflow-wrap: break-word;
}

.full-stack-container {
  margin-top: 20px;
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.course-name-light-fs {
  background-color: #21a6f837;
  border: 2px solid #21a6f87f;
}

.course-name-medium-fs {
  background-color: #039eff83;
  border: 2px solid #058ce0ac;
}

.course-name-green-fs {
  background-color: rgba(105, 235, 146, 0.438);
  border: 2px solid rgb(70, 216, 131);
}

.course-name-light-fs:hover,
.course-name-medium-fs:hover,
.course-name-green-fs:hover {
  box-shadow: 3px 3px 7px 1px black;
}

.course-row {
  display: flex;
  flex-direction: column;
}

.course-name-light {
  margin-bottom: 10px;
}

.line-down-target {
  position: relative;
}

.line-down-target::after {
  content: '';
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 22px;
  background-color: black;
}

.line-down-target::before {
  content: '';
  position: absolute;
  top: calc(90% + 21px);
  left: 50%;
  transform: translateX(-50%);
  width: 175px;
  height: 2px;
  background-color: black;
}

.vertical-line-target {
  position: relative;
}

.vertical-line-target::before {
  content: '';
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 22px;
  background-color: black;
}

.vertical-line-target::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border: solid black;
  background-color: black;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid black;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

@media (min-width: 769px) {
  .container-career-paths {
    margin-top: 90px;
    margin-bottom: 60px;
    padding: 20px;
  }

  .paths-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
  }

  .path {
    width: calc(50% - 20px);
    margin-top: 50px;
    padding: 2%;
  }

  .title-in-circle {
    width: 250px;
    height: 250px;
    font-size: 16px;
  }

  .course-name-light,
  .course-name-medium,
  .course-name-green {
    min-width: 300px;
    max-width: 450px;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
  }

  .course-name-light-fs,
  .course-name-medium-fs {
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    min-width: 200px;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .full-stack-container {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
  }

  .container-career-paths h1 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 40px;
  }

  .line-down-target {
    position: relative;
  }

  .line-down-target::before {
    width: 222px;
  }

  .vertical-line-target {
    position: relative;
  }

  .vertical-line-target::before {
    top: -33px;
    height: 33px;
  }
}

@media screen and (min-width: 1025px) {
  .paths-duration {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .course-name-light,
  .course-name-medium,
  .course-name-green {
    min-width: 520px;
    font-size: 18px;
    padding: 20px;
    height: 50px;
  }

  .course-name-light-fs,
  .course-name-medium-fs {
    font-size: 18px;
    font-weight: 400;
    min-width: 320px;
  }

  .full-stack-container {
    gap: 30px;
  }

  .line-down-target::before {
    width: 352px;
  }
}
