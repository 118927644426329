.Logo {
  width: 60px;
  display: block;
  margin-left: 5px;
}

.nav-container {
  width: 100%;
  padding: 0 1.5rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--black-color);
  box-shadow: 0 2px 16px hsla(220, 32%, 8%, 0.3);
  z-index: var(--z-fixed);
}

.nav {
  height: var(--header-height);
}

.nav__menu {
  display: none;
  position: absolute;
  left: 0;
  top: var(--header-height);
  width: 100%;
  z-index: 1000;
}

.nav__logo,
.nav__burger,
.nav__close {
  color: var(--white-color);
}

.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__list {
  margin-right: 0px;
  background-color: var(--black-color);
  padding: 15px;
}

.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
  font-size: 1.5rem;
}

.ri-close-line .nav__close,
.ri-menu-line .nav__burger {
  font-size: medium;
}

.nav__close {
  opacity: 0;
}

.dropdown__menu {
  background-color: var(--black-color);
  list-style: none;
  padding: 0;
  display: none;
  flex-wrap: wrap;
  white-space: nowrap;
  position: absolute;
  z-index: 1000;
}

.show-dropdown {
  display: block;
}

.services-dropdown {
  top: var(--header-height);
}

.classes-dropdown {
  overflow-y: auto;
  max-height: 75vh;
  stroke: none;
  scrollbar-width: none;
}

.arrow-icon {
  margin-left: 5px;
  transition: transform 0.3s;
}

.arrow-icon-open .arrow-icon {
  transform: rotate(180deg);
}

.arrow-icon-open .arrow-icon-classes {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.dropdown__menu a {
  color: var(--white-color);
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.3s, background-color 0.3s;
}

.dropdown__menu a:hover {
  color: rgb(12, 157, 254);
}

.dropdown__menu li {
  padding: 0.7rem;
  flex: 1;
}

.title-classes {
  font-weight: bold;
  font-size: 15px;
  padding: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: var(--white-color);
  display: block;
}

.category-title {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
  color: var(--white-color);
  display: block;
  transition: color 0.3s, background-color 0.3s;
}

.category-title:hover {
  color: rgb(12, 157, 254);
  cursor: pointer;
}

.course-names {
  padding: 5px;
  font-size: 14px;
}

.nav__link {
  color: var(--white-color);
  background-color: var(--black-color);
  font-weight: var(--font-semi-bold);
  margin-right: 5px;
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav__apply-button-container {
  display: none;
}

.nav__link.apply-button {
  white-space: nowrap;
  background-color: #0077cc;
  color: white;
  font-size: medium;
  padding: 8px 20px;
  border: none;
  border-radius: 0px;
  display: block;
  text-align: center;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.nav__link.apply-button:hover {
  background-color: #014c98;
}

.show-menu {
  display: block;
}

.show-icon .nav__burger {
  opacity: 0;
}

.show-icon .nav__close {
  opacity: 1;
}

/* media query for mid-large screens */
@media screen and (min-width: 768px) {
  .Logo {
    width: 80px;
    margin-left: 25px;
  }

  .nav-container {
    max-width: 1120px;
    margin-inline: 1.5rem;
  }

  .nav {
    height: calc(var(--header-height) + 2rem);
    display: flex;
    justify-content: space-between;
  }

  .nav__menu {
    display: flex;
    position: static;
    width: auto;
  }

  .nav__list {
    align-items: center;
    margin-left: 5px;
    height: 100%;
    display: flex;
    column-gap: 2.75rem;
  }

  .nav__link {
    height: auto;
    padding: 0;
    justify-content: initial;
    column-gap: 0.25rem;
  }

  .nav__link.apply-button {
    display: none;
  }

  .nav__apply-button-container {
    display: flex;
    align-items: center;
    width: 150px;
  }

  .nav__link.apply-button-large-screen {
    width: 90px;
    white-space: nowrap;
    background-color: #0077cc;
    color: white;
    font-size: medium;
    padding: 10px;
    border: none;
    display: block;
    text-align: center;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 200ms;
    transition: all 0.3s;
  }

  .nav__link.apply-button-large-screen:hover {
    transform: scale(1.1);
  }

  .nav__toggle {
    display: none;
  }

  .dropdown__menu {
    top: 84px;
    scrollbar-width: none;
  }

  .classes-dropdown {
    top: 0;
    left: 100%;
    max-height: 100vh;
  }

  .arrow-icon-classes {
    margin-left: 10px;
    transform: rotate(270deg);
    transition: 0.3s;
  }

  .arrow-icon-open .arrow-icon-classes {
    transform: rotate(-270deg);
    margin-left: 20px;
  }

  .title-classes {
    margin-top: 10px;
    font-size: 16px;
  }

  .category-title {
    font-size: 15px;
  }

  .course-names {
    font-size: 15px;
    padding: 10px;
  }

  .category-title {
    font-size: 15px;
  }

  .course-names {
    font-size: 15px;
  }
}
